import Image from 'next/image';
import { FC, MouseEventHandler } from 'react';
import { ArrorwBackIcon } from '../Icons';
import SvgLike from '../SvgLikeIcon';
import dynamic from 'next/dynamic';
import {
  IconsWrapper,
  LikeIconWrapper,
  MobileLineWrapper,
  ShareIconWrapper,
  Title,
  TitleWrapper,
} from './style';
import CalendarIcon from '/public/icons/calendar.svg';
import ShareIcon from '/public/icons/share.svg';
import SpeakerMutedIcon from '/public/icons/speakerMutedIcon.svg';

interface IMobileHeaderLine {
  title: string;
  isRunningLiveAuction: boolean;
  onBackBtnClick: () => void;
  hasLikeBtn?: boolean;
  onLikeBtnClick?: MouseEventHandler<HTMLElement>;
  hasCalendarBtn?: boolean;
  hasSpeakerBtn?: boolean;
  onSpeakerBtnClick?: () => void;
  isItemSelected?: boolean;
  hasShareBtn?: boolean;
  onShareBtnClick?: MouseEventHandler<HTMLElement>;
}

const DynamicWebAgora = dynamic(() => import('../../AuctionDetails/WebAgora'), {
  ssr: false,
});

const MobileHeaderLine: FC<IMobileHeaderLine> = ({
  title,
  isRunningLiveAuction,
  onBackBtnClick,
  hasLikeBtn = false,
  onLikeBtnClick,
  hasSpeakerBtn,
  onSpeakerBtnClick,
  hasCalendarBtn = false,
  isItemSelected = false,
  hasShareBtn = true,
  onShareBtnClick,
}) => {
  return (
    <MobileLineWrapper id="mobile-line-wrapper">
      <TitleWrapper onClick={onBackBtnClick}>
        <ArrorwBackIcon width={18} height={22} />
        <Title>{title}</Title>
      </TitleWrapper>
      {isRunningLiveAuction ? (
        <DynamicWebAgora />
      ) : (
        <IconsWrapper>
          {hasShareBtn && (
            <ShareIconWrapper onClick={onShareBtnClick}>
              <Image src={ShareIcon} alt="share" />
            </ShareIconWrapper>
          )}
          {hasCalendarBtn && (
            <div>
              <Image src={CalendarIcon} alt="calendar" />
            </div>
          )}
          {hasLikeBtn && (
            <LikeIconWrapper onClick={onLikeBtnClick}>
              <SvgLike color={isItemSelected ? '#1c4d8e' : 'none'} />
            </LikeIconWrapper>
          )}
          {hasSpeakerBtn && (
            <div onClick={onSpeakerBtnClick}>
              <Image src={SpeakerMutedIcon} alt="mute" />
            </div>
          )}
        </IconsWrapper>
      )}
    </MobileLineWrapper>
  );
};

export default MobileHeaderLine;
