import styled from 'styled-components';
import colors from '@pickles/shared/utils/colors';

interface IDropDownWrapper {
  rightPosition: string;
}

export const DropDownWrapper = styled.div<IDropDownWrapper>`
  position: absolute;
  top: 54px;
  right: ${({ rightPosition }) => rightPosition}px;
  z-index: 20;
  width: 200px;
  border: 1px solid ${colors.mischka};
  background: ${colors.white};
  border-radius: 12px;
  max-height: 300px;
  overflow-y: auto;
`;

export const SelectWrapper = styled.div`
  margin-top: 10px;
  /* margin-bottom: 10px; */
  display: flex;
  flex-direction: column;
`;

export const DropdownDivider = styled.div`
  width: 100%;
  height: 1px;
  border: 0.5px solid ${colors.mischka};
  margin-top: 20px;
  margin-bottom: 10px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px 10px 20px;
  position: sticky;
  border-top: 1px solid ${colors.mischka};
  bottom: 0;
  background: ${colors.white};
`;

export const ClearButton = styled.div`
  cursor: pointer;
  font-family: BasierCircle-Semibold;
  font-size: 15px;
  color: ${colors.polo_blue};
`;

export const ApplyButton = styled.div`
  cursor: pointer;
  width: 69px;
  height: 38px;
  border-radius: 8px;
  background: ${colors.blumine_main_blue};
  color: white;
  font-family: BasierCircle-Semibold;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
