import colors from '@pickles/shared/utils/colors';
import styled from 'styled-components';

export const AuctionPlaceMapContainer = styled.a`
  display: flex;
  align-items: center;
  cursor: pointer;
  div {
    margin-right: 8px;
    display: flex;
    align-items: center;
  }
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;

  p {
    font-size: 14px;
    line-height: 20px;
    font-family: BasierCircle;
    text-align: left;
    color: ${colors.san_juan_grey};
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: pre-wrap;
    max-width: 100%;
    margin-left: 6.5px;
    margin-bottom: 0 !important;
  }

  svg {
    flex-shrink: 0;
  }
`;

export const PinIconWrapper = styled.div`
  flex-shrink: 0;
`;
