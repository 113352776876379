import Image from 'next/image';
import React from 'react';
import { CardPinIcon } from '../../Icons';
import { AuctionPlaceMapContainer, PinIconWrapper } from './styles';
import placeMapIcon from '/public/icons/placeMap.svg';

type PropsType = {
  name?: string;
  clickable?: boolean;
};

export const PlaceMapMessage = ({ name, clickable = true }: PropsType) => {
  const handleLocationClick = (e: React.MouseEvent<HTMLElement>) => {
    if (!clickable) {
      return e.preventDefault();
    }

    window.open(`https://www.google.com/maps/search/${name},17z`, '_blank');
  };

  return (
    <AuctionPlaceMapContainer
      id="map-container"
      target="_blank"
      rel="noopener noreferrer"
      onClick={(event) => handleLocationClick(event)}
    >
      <CardPinIcon />
      <p>{name}</p>
    </AuctionPlaceMapContainer>
  );
};
