import colors from '@pickles/shared/utils/colors';
import styled from 'styled-components';
import sizes from '../../../util/sizes';

interface ILikeIconWrapper {
  hasNextImg?: boolean;
}

export const MobileLineWrapper = styled.div`
  height: 62px;
  padding: 0 25px 0 19px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  position: sticky;
  top: 0;
  z-index: 1;
`;

export const TitleWrapper = styled.div`
  display: flex;
  column-gap: 20px;
  align-items: center;
`;

export const Title = styled.div`
  font-family: BasierCircle-Semibold;
  font-size: 19px;
  color: ${colors.blumine_main_blue};
  /* width: calc(100% - 60px); */
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const BackBtnWrapper = styled.div`
  cursor: pointer;
  margin-right: 29px;
`;

export const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ShareIconWrapper = styled.div`
  margin-right: 25px;

  @media (max-width: ${sizes.M}) {
    margin-left: 5px;
  }
`;

export const LikeIconWrapper = styled.div<ILikeIconWrapper>`
  margin-right: ${({ hasNextImg = false }) => (hasNextImg ? '21px' : '0')};
`;
