import map from 'lodash/map';
import React, { forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputCheckbox from '../Input/InputCheckbox';
import {
  ApplyButton,
  ButtonsWrapper,
  ClearButton,
  DropdownDivider,
  DropDownWrapper,
  SelectWrapper,
} from './SelectDropDown.style';

export type Options = {
  key: string;
  label: string;
  value: string;
};

export enum SELECT_MODE {
  SINGLE,
  MULTIPLE,
}

interface SelectDropDownProps {
  options: Options[];
  selectedOptions?: string[];
  mode: SELECT_MODE;
  rightPosition?: string;
  onClickOutside: (bool: boolean) => void;
  onClickApply: (options: string[]) => void;
}

const SelectDropDown = forwardRef(
  (
    {
      rightPosition,
      onClickOutside,
      options,
      mode,
      selectedOptions,
      onClickApply,
    }: SelectDropDownProps,
    ref: React.ForwardedRef<HTMLElement>,
  ) => {
    const [singleItem, setSingleItem] = useState<string>('');
    const [multiItem, setMultiItem] = useState<string[]>([]);
    const { t } = useTranslation();

    const onSelectedItem = (value: string) => {
      if (mode === SELECT_MODE.SINGLE) {
        setSingleItem(singleItem === value ? '' : value);
      } else {
        multiItem.includes(value)
          ? setMultiItem(multiItem.filter((item) => item !== value))
          : setMultiItem([...multiItem, value]);
      }
    };

    const handleClickOutside = (event: MouseEvent) => {
      // @ts-ignore
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside(false);
      }
    };

    const onApplyOption = () => {
      switch (mode) {
        case SELECT_MODE.SINGLE: {
          onClickApply([singleItem]);
          break;
        }
        case SELECT_MODE.MULTIPLE: {
          onClickApply(multiItem);
        }
        // no default
      }

      onClickOutside(false);
    };

    const onClearPress = () => {
      setSingleItem('');
      setMultiItem([]);
    };

    useEffect(() => {
      document.addEventListener('click', handleClickOutside);
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    });

    useEffect(() => {
      if (selectedOptions && selectedOptions.length) {
        if (!mode) {
          setSingleItem(selectedOptions[0]);
        } else {
          setMultiItem([...selectedOptions]);
        }
      }
    }, [selectedOptions, mode]);

    return (
      <DropDownWrapper ref={ref} rightPosition={rightPosition} id="select-dropdown-wrapper">
        <SelectWrapper id="select-wrapper">
          {map(options, (item, index) => {
            const getActiveItem = () => {
              if (!mode) {
                return singleItem === item.value;
              } else if (mode) {
                return !!multiItem.includes(item.value);
              }
            };
            return (
              // <CheckboxLineWrapper id={'select-dropdown'} key={item.label}>
              <InputCheckbox
                checked={getActiveItem()}
                onChange={() => {
                  onSelectedItem(item.value);
                }}
                id={item.value}
                name={item.value}
                key={index}
              >
                {item.label}
              </InputCheckbox>
            );
          })}
        </SelectWrapper>
        <ButtonsWrapper>
          <ClearButton id={'clear-btn'} onClick={onClearPress}>
            {t('buttons:clear')}
          </ClearButton>
          <ApplyButton id={'apply-btn'} onClick={onApplyOption}>
            {t('buttons:apply')}
          </ApplyButton>
        </ButtonsWrapper>
      </DropDownWrapper>
    );
  },
);

SelectDropDown.displayName = 'SelectDropDown';

export default SelectDropDown;
