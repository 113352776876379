import dynamic from 'next/dynamic';
import React, { FC } from 'react';
import Portal from '.';

const DynamicPdfReader = dynamic(() => import('../Shared/PDFViewer/index'), {
  ssr: false,
});

interface PDFPortalProps {
  fileLocation: string;
  onClickClose: () => void;
}

const PDFPortal: FC<PDFPortalProps> = ({ fileLocation, onClickClose }) => {
  return (
    <Portal wrapperId="document-wrapper-portal">
      <DynamicPdfReader fileLocation={fileLocation} handleClose={onClickClose} />
    </Portal>
  );
};

export default PDFPortal;
