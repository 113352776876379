import { IGeneral } from '@pickles/shared/models';
import { FILTER_OBJECT_AUCTION_CATEGORY } from '@pickles/shared/models/general.types';
import Image from 'next/image';
import { FC, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SelectDropDown, { SELECT_MODE } from './SelectDropDown';
import { DetailFiltersContainer, DropDownLabel, DropdownWrapper } from './style';
import ArrowDown from '/public/icons/dropDownArrowIcon.svg';

interface AuctionDetailFilterProps {
  onApplyFilter: (
    filters: string[],
    filterType: 'year' | 'make' | 'model' | 'location' | 'condition' | 'category',
  ) => void;
  filters: {
    selected: IGeneral.ItemsListingFilter;
    available: IGeneral.ItemsListingFilter;
  };
}

const ItemFilters: FC<AuctionDetailFilterProps> = ({ onApplyFilter, filters }) => {
  const { t } = useTranslation();
  const [isYearTypeOpen, setIsYearTypeOpen] = useState(false);
  const [isMakeTypeOpen, setIsMakeTypeOpen] = useState(false);
  const [isModelTypeOpen, setIsModelTypeOpen] = useState(false);
  const [isLocationTypeOpen, setIsLocationTypeOpen] = useState(false);
  const [isConditionTypeOpen, setIsConditionTypeOpen] = useState(false);
  const [isCategoryTypeOpen, setIsCategoryTypeOpen] = useState(false);

  const yearTypeSelectRef = useRef(null);
  const makeTypeSelectRef = useRef(null);
  const modelTypeSelectRef = useRef(null);
  const locationTypeSelectRef = useRef(null);
  const conditionTypeSelectRef = useRef(null);
  const categoryTypeSelectRef = useRef(null);

  const { years, makes, models, locations, conditions } = useMemo(() => {
    return {
      makes: filters?.available?.make || [],
      years: filters?.available?.year || [],
      models: filters?.available?.model || [],
      locations: filters?.available?.location || [],
      conditions: filters?.available?.condition || [],
    };
  }, [filters?.available]);

  return (
    <DetailFiltersContainer id="Detail-filters-container">
      <DropdownWrapper id="year-picker-wrapper">
        <DropDownLabel
          id="auction-year-picker"
          onClick={() => setIsYearTypeOpen(!isYearTypeOpen)}
          isActive={isYearTypeOpen}
        >
          {t('fields:year')}
          <div
            style={{
              marginLeft: '9px',
            }}
          >
            <Image id="arrow-down" src={ArrowDown} alt="down" />
          </div>
        </DropDownLabel>
        {isYearTypeOpen && (
          <SelectDropDown
            ref={yearTypeSelectRef}
            selectedOptions={filters?.selected?.year}
            onClickOutside={setIsYearTypeOpen}
            rightPosition="-40"
            mode={SELECT_MODE.MULTIPLE}
            onClickApply={(options) => {
              onApplyFilter(options, 'year');
            }}
            options={years.map((item: string) => ({
              key: item,
              label: item,
              value: item,
            }))}
          />
        )}
      </DropdownWrapper>
      <DropdownWrapper id="make-picker-wrapper">
        <DropDownLabel
          id="auction-make-picker"
          onClick={() => setIsMakeTypeOpen(!isMakeTypeOpen)}
          isActive={isMakeTypeOpen}
        >
          {t('labels:make')}
          <div
            style={{
              marginLeft: '9px',
            }}
          >
            <Image id="arrow-down" src={ArrowDown} alt="down" />
          </div>
        </DropDownLabel>
        {isMakeTypeOpen && (
          <SelectDropDown
            ref={makeTypeSelectRef}
            selectedOptions={filters?.selected?.make}
            onClickOutside={setIsMakeTypeOpen}
            rightPosition="-40"
            mode={SELECT_MODE.MULTIPLE}
            onClickApply={(options) => {
              onApplyFilter(options, 'make');
            }}
            options={makes.map((item: string) => ({
              key: item,
              label: item,
              value: item,
            }))}
          />
        )}
      </DropdownWrapper>
      <DropdownWrapper id="model-picker-wrapper">
        <DropDownLabel
          id="auction-model-picker"
          onClick={() => setIsModelTypeOpen(!isModelTypeOpen)}
          isActive={isModelTypeOpen}
        >
          {t('labels:model')}
          <div
            style={{
              marginLeft: '9px',
            }}
          >
            <Image id="arrow-down" src={ArrowDown} alt="down" />
          </div>
        </DropDownLabel>
        {isModelTypeOpen && (
          <SelectDropDown
            ref={modelTypeSelectRef}
            selectedOptions={filters?.selected?.model}
            onClickOutside={setIsModelTypeOpen}
            rightPosition="-40"
            mode={SELECT_MODE.MULTIPLE}
            onClickApply={(options) => {
              onApplyFilter(options, 'model');
            }}
            options={models.map((item: string) => ({
              key: item,
              label: item,
              value: item,
            }))}
          />
        )}
      </DropdownWrapper>

      {filters?.available.location && (
        <DropdownWrapper id="location-picker-wrapper">
          <DropDownLabel
            id="auction-location-picker"
            onClick={() => setIsLocationTypeOpen(!isLocationTypeOpen)}
            isActive={isLocationTypeOpen}
          >
            {t('labels:location')}
            <div
              style={{
                marginLeft: '9px',
              }}
            >
              <Image id="arrow-down" src={ArrowDown} alt="down" />
            </div>
          </DropDownLabel>
          {isLocationTypeOpen && (
            <SelectDropDown
              ref={locationTypeSelectRef}
              selectedOptions={filters?.selected?.location}
              onClickOutside={setIsLocationTypeOpen}
              mode={SELECT_MODE.MULTIPLE}
              rightPosition="-40"
              onClickApply={(options) => {
                onApplyFilter(options, 'location');
              }}
              options={locations.map((item: string) => ({
                key: item,
                label: item,
                value: item,
              }))}
            />
          )}
        </DropdownWrapper>
      )}

      {filters?.available.condition && (
        <DropdownWrapper id="condition-picker-wrapper">
          <DropDownLabel
            id="auction-condition-picker"
            onClick={() => setIsConditionTypeOpen(!isConditionTypeOpen)}
            isActive={isConditionTypeOpen}
          >
            {t('labels:condition')}
            <div
              style={{
                marginLeft: '9px',
              }}
            >
              <Image id="arrow-down" src={ArrowDown} alt="down" />
            </div>
          </DropDownLabel>
          {isConditionTypeOpen && (
            <SelectDropDown
              ref={conditionTypeSelectRef}
              selectedOptions={filters?.selected?.condition}
              onClickOutside={setIsConditionTypeOpen}
              mode={SELECT_MODE.MULTIPLE}
              rightPosition="-40"
              onClickApply={(options) => {
                onApplyFilter(options, 'condition');
              }}
              options={conditions.map((item: string) => ({
                key: item,
                label: item,
                value: item,
              }))}
            />
          )}
        </DropdownWrapper>
      )}

      {filters?.available.category && (
        <DropdownWrapper id="category-picker-wrapper">
          <DropDownLabel
            id="auction-category-picker"
            onClick={() => setIsCategoryTypeOpen(!isCategoryTypeOpen)}
            isActive={isCategoryTypeOpen}
          >
            {t('labels:category')}
            <div
              style={{
                marginLeft: '9px',
              }}
            >
              <Image id="arrow-down" src={ArrowDown} alt="down" />
            </div>
          </DropDownLabel>
          {isCategoryTypeOpen && (
            <SelectDropDown
              ref={categoryTypeSelectRef}
              selectedOptions={filters?.selected?.category}
              onClickOutside={setIsCategoryTypeOpen}
              mode={SELECT_MODE.MULTIPLE}
              rightPosition="-40"
              onClickApply={(options) => {
                onApplyFilter(options, 'category');
              }}
              options={FILTER_OBJECT_AUCTION_CATEGORY}
            />
          )}
        </DropdownWrapper>
      )}
    </DetailFiltersContainer>
  );
};

export default ItemFilters;
