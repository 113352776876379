import colors from '@pickles/shared/utils/colors';
import styled from 'styled-components';
import sizes from '../../../util/sizes';

export const DetailsButtonHeight = '44px';

export const FiltersRowContainer = styled.div`
  display: flex;
  width: inherit;
  justify-content: space-between;
  margin-top: 30px;

  @media (max-width: ${sizes.S}) {
    margin-top: 0;
    justify-content: center;
  }
`;

export const DetailsItemsCount = styled.div`
  font-weight: 600;
  font-size: 19px;
  color: #263a54;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  margin-right: 32px;

  span {
    margin-right: 4px;
  }

  @media (max-width: ${sizes.S}) {
    width: fit-content;
    span {
      margin: 4px;
    }
  }
`;

export const DetailFiltersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;

  @media (max-width: ${sizes.S}) {
    order: 3;
  }
`;

export const DetailsItemsCountContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  @media (max-width: ${sizes.M}) {
    margin-left: 16px;
    margin-bottom: 16px;
  }
  @media (max-width: ${sizes.S}) {
    /* margin-left: 0px; */
    width: 100%;
    flex-wrap: wrap;
    row-gap: 5px;
  }
`;

export const DropdownWrapper = styled.div`
  position: relative;
`;

interface IAuctionTypePicker {
  isActive: boolean;
}

export const DropDownLabel = styled.div<IAuctionTypePicker>`
  /* width: 137px; */
  padding: 12px 13px 12px;
  height: 46px;
  border: ${({ isActive }) =>
    isActive ? `1px solid ${colors.blumine_main_blue}` : `1px solid ${colors.mischka}`};
  border-radius: 12px;
  color: ${colors.san_juan_grey};
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: BasierCircle-Medium;
  font-size: 15px;
  margin-right: 12px;
  cursor: pointer;
  line-height: 20px;
`;
