import { FC } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';

import Config from '@pickles/shared/config/env';
import { i18n } from '../../pages/_app';
import { IMedia } from '../../util/types';

interface MetaTagsProps {
  title: string | undefined;
  description?: string;
  image?: string;
  /** SEO Data from strapi */
  seo?: {
    metaTitle: string;
    metaDescription: string;
    shareImage?: IMedia;
    keywords?: string | null;
  };
}

const MetaTags: FC<MetaTagsProps> = ({
  title,
  description = i18n.t('infos:meta_description'),
  image = '',
  seo,
}) => {
  const router = useRouter();

  const formattedTitle = seo?.metaTitle || `${title} - ${i18n.t('infos:meta_title')}`;
  const _description = seo?.metaDescription || description;
  const _image = seo?.shareImage?.data?.attributes.url || image;
  const _keywords = seo?.keywords;

  return (
    <Head>
      {/* <!-- Primary Meta Tags --> */}
      <title>{formattedTitle}</title>

      <link rel="canonical" href={`${Config.HOST_URL}${router.asPath}`} key="canonical" />

      <meta name="description" content={_description} />
      {_keywords && <meta name="keywords" content={_keywords} />}

      {/* <!-- Open Graph / Facebook --> */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={`${Config.HOST_URL}${router.asPath}`} />
      <meta property="og:title" content={formattedTitle} />
      <meta property="og:description" content={_description} />
      {_image && <meta property="og:image" content={_image} />}

      {/* <!-- Twitter --> */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@PicklesAsia" />
      <meta name="twitter:title" content={formattedTitle} />
      <meta name="twitter:description" content={_description} />
      {_image && <meta name="twitter:image" content={_image} />}
    </Head>
  );
};

export default MetaTags;
