import colors from '@pickles/shared/utils/colors';
import { ChangeEventHandler, FC } from 'react';
import styled from 'styled-components';

interface InputCheckboxProps {
  checked?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  id: string;
  name: string;
}

const InputCheckbox: FC<InputCheckboxProps> = ({ children, ...props }) => {
  return (
    <InputCheckboxWrapper checked={props.checked}>
      <input type="checkbox" {...props} />
      {children}
      <span className="checkbox"></span>
    </InputCheckboxWrapper>
  );
};

export default InputCheckbox;

export const InputCheckboxWrapper = styled.label<{ checked?: boolean }>`
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 20px;
  margin-right: 15px;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  color: ${({ checked }) => (checked ? colors.blumine_main_blue : colors.rhino_black)};
  position: relative;
  padding-left: 28px;

  & > input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ span {
      background-color: ${colors.blumine_main_blue};
      border: none;
    }

    &:checked ~ span::after {
      display: block;
    }
  }

  & > span {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    background-color: white;
    border: 1px solid ${colors.mischka};
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
      content: '';
      width: 12.7px;
      height: 10px;

      background-image: url(/icons/checkboxCheckIcon.svg);
      background-repeat: no-repeat;
    }
  }
`;
